import React from 'react'
import Layout from "../components/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo";

const Accessibility = ({data}) => {
  
  const { page } = data
  const title = page.title
  
    return (
        <Layout headerClass="bg-white relative">
          <SEO title={title} />
          <div className="flex flex-col w-full">
            <div className="flex-shrink-0 py-0 flex flex-row">
              <div className="markdown-body">
                <div dangerouslySetInnerHTML={{__html: page.content}}></div>
              </div>
            </div>
          </div>
        </Layout>
    )
}

export const query = graphql`
    query AccessibilityStatement {
        page: wpPage(slug: {eq: "accessibility-statement"}) {
            content
            slug
            title
        }
    }`

export default Accessibility
